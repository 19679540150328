import $ from './libs/jquery'
import './libs/bootstrap'
import 'jquery-validation'
import 'vite/modulepreload-polyfill'
import htmx from 'htmx.org';

import {
  initBasicSlider,
  initProductSlider,
  initQuotesSlider,
  initStandardSlider,
  initImageTextSlider
} from './addons/slick'
import { initDesktopMenu, initMobileMenu } from './addons/header'
import { initAccordions } from './addons/accordion'
import { initBlog } from './addons/blog'
import { initCookiePolicy } from './addons/cookie-policy'
import initFormLocale from './addons/forms'
import { initLocalScroll } from './addons/localscroll'
import { initModalBoxes } from './addons/modal'
import { initSearchFiltersForm } from './addons/search'
import { showTabFromHash } from './addons/tabs'
import { initParallax } from './addons/parallax'

window.$ = $
window.jQuery = $
window.htmx = htmx

$(() => {
  initMobileMenu()
  initDesktopMenu()
  initStandardSlider()
  initProductSlider()
  initQuotesSlider()
  initBasicSlider()
  initImageTextSlider()
  initBlog()
  initModalBoxes()
  initCookiePolicy()
  initAccordions()
  initSearchFiltersForm()
  initLocalScroll()
  initFormLocale()
  initParallax()

  $(window)
    .on('hashchange', () => {
      showTabFromHash()
    })
    .trigger('hashchange')
})

/**
 * VSS Section Nav
 */
$('.feature-menu .section-name button.section-nav-toggle').click(function (e) {
  const el = $(this)
  const container = el.closest('.container')
  const menu = container.find('.section-menu')

  if (el.hasClass('collapsed')) {
    el.removeClass('collapsed')
    menu.addClass('expanded')
  } else {
    el.addClass('collapsed')
    menu.removeClass('expanded')
  }
})

/**
 * Back to top Button
 */
const btn = $('.back-to-top')

$(window).scroll(function () {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show')
  } else {
    btn.removeClass('show')
  }
})

btn.on('click', function (e) {
  e.preventDefault()
  $('html, body').animate({ scrollTop: 0 }, '300')
})

/**
 * Automatically logs the user in when the webpage loads.
 */
window.autologin = function () {
  const pageEditUrl = `${window.location.pathname}?edit`
  if (!!window.CMS) {
    window.location.href = pageEditUrl
  } else {
    const nextParam = encodeURIComponent(pageEditUrl)
    window.location.href = `/admin/login/?autologin=1&next=${nextParam}`
  }
}
